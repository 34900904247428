import { toRef, ref } from 'vue'
import { useNuxtApp } from '#app'
import { useAPI, useSharedPromise, useHydrationStore } from '#imports'
import type { Suggestion } from '@/modules/nuxt-api/models/Suggestion'

type State = {
  searchValue: string
}

const showHeaderMegamenu = ref(false)
const openHeaderMegamenu = ref(false)

export function useSearchStore () {
  const nuxtApp = useNuxtApp()
  const api = useAPI()
  const state = useHydrationStore<State>('search-store', {
    searchValue: ''
  }, { disableHydration: true })

  async function searchProductsByQuery (searchQuery: string) {
    // Поправить параметры когда разберемся с запросом
    const suggestions = await nuxtApp.runWithContext(() => useSharedPromise(['suggestions', searchQuery], async () => {
      const { getSuggestions } = api.search()

      return await getSuggestions(searchQuery)
    }))

    return suggestions as Suggestion[]
  }

  let timeout: NodeJS.Timeout | undefined

  const toggleMegamenu = () => {
    if (!showHeaderMegamenu.value) {
      openMegamenu()
    } else {
      closeMegamenu()
    }
  }

  const openMegamenu = () => {
    showHeaderMegamenu.value = true
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      openHeaderMegamenu.value = true
      clearTimeout(timeout)
    }, 0)
  }

  const closeMegamenu = () => {
    showHeaderMegamenu.value = false
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      openHeaderMegamenu.value = false
      clearTimeout(timeout)
    }, 200)
  }

  return {
    searchProductsByQuery,
    searchValue: toRef(state.value, 'searchValue'),

    toggleMegamenu,
    closeMegamenu,
    showHeaderMegamenu,
    openHeaderMegamenu
  }
}
